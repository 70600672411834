import React, { useEffect, useState } from "react";
import "../Style/Header.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const hash = location.hash.substring(1);
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location.hash]);

  // Function to handle link click and close the Navbar
  const handleLinkClick = () => {
    setIsOpen(false); // Close the Navbar menu
  };

  return (
    <div>
      <div id="logos">
        <div className="left-logo">
          <img
            src="assets/samaj-logo.png"
            alt="patel-samaj"
            className="khodaldham-logo"
          />
          {/* <img
            src="https://www.khodaldhamtrust.org/upload_data/general/5e2fcf3588f5d_khodaldham_logo_english.svg"
            alt="Khodaldham English"
            className="khodaldham-eng"
          /> */}
        </div>
        <div className="right-logos">
          <img
            src="https://www.khodaldhamtrust.org/upload_data/general/5dfbcbd162db6_jay-sardar.png"
            alt="Jay Sardar"
            className="jay-sardar"
          />
          <img
            src="https://www.khodaldhamtrust.org/upload_data/general/5dfbcbd162f43_jay-bhojalram.png"
            alt="Jay Bhojalram"
            className="jay-bhojalram"
          />
        </div>
      </div>

      <div className={`topnav${isScrolled ? " scrolled" : ""}`}>
        <Navbar expand="lg">
          <GiHamburgerMenu
            className="toggle-btn"
            onClick={() => setIsOpen(!isOpen)}
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className={`${isOpen ? "show" : ""}`}
          >
            <div className="nav1">
              <Nav className="me-auto">
                <Nav.Link href="/#hero" onClick={handleLinkClick}>
                  Home
                </Nav.Link>
                <Nav.Link href="/#about" onClick={handleLinkClick}>
                  About Us
                </Nav.Link>
                <Nav.Link href="/#event" onClick={handleLinkClick}>
                  Event
                </Nav.Link>
                <Nav.Link id="trustee" href="/#trustee" onClick={handleLinkClick}>
                  Trustee
                </Nav.Link>
                <Nav.Link href="/#gallery" onClick={handleLinkClick}>
                  Gallery
                </Nav.Link>
                <Link to="/contact" onClick={handleLinkClick}>
                  Contact Us
                </Link>
                {/* <Link to="/booking" onClick={handleLinkClick}>
                  Booking
                </Link> */}
              </Nav>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;
