import ContactForm from "./Components/ContactForm";
import BookingForm from "./Components/BookingForm";
import Gallery from "./Components/Gallery";
import GalleryImages from "./Components/GalleryImages";
import Header from "./Components/Header";
import HeroSection from "./Components/HeroSection";
import CommunityForm from "./Components/CommunityForm";
import Footer from "./Components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./Components/About";
import Trustee from "./Components/Trustee";
import "./App.css";
import Event from "./Components/Event";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <Router>

        <Header />

        <ToastContainer />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HeroSection />
                <About />
                <Event />
                <Trustee />
                <Gallery />
                {/* <CommunityForm /> */}
              </>
            }
          />
          <Route path="/gallery/:imgurlId" element={<GalleryImages />} />
          <Route path="/contact" element={<ContactForm />} />
          {/* <Route path="/booking" element={<BookingForm />} /> */}
        </Routes>

        <Footer />
      </Router>
    </>
  );
}

export default App;
