import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBTypography,
  MDBCardText,
  MDBBtn,
} from "mdb-react-ui-kit";
import { getOfficeUser } from "../Store/slice/samajSlice";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../Style/Trustee.css";

const Trustee = () => {
  const dispatch = useDispatch();
  const { officeUserData, loading } = useSelector(
    (state) => state.samaj || { officeUserData: [], loading: false }
  );
  const sliderRef = useRef(null);

  // console.log('officeUserData :>> ', officeUserData);

  useEffect(() => {
    if (!loading && officeUserData.length === 0) {
      dispatch(getOfficeUser());
    }

    const handleScroll = () => {
      const elements = document.querySelectorAll(".custom-card");
      elements.forEach((element) => {
        const elementTop = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (elementTop < windowHeight) {
          element.classList.add("visible");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger once on mount to check initial visibility

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch, loading, officeUserData.length]);

  const settings = {
    dots: true,
    infinite: officeUserData?.data?.data.length > 1,
    speed: 500,
    slidesToShow:
      officeUserData?.data?.data.length === 3
        ? 3
        : 4 || officeUserData?.data?.data.length === 2
        ? 2
        : 4 || officeUserData?.data?.data.length === 1
        ? 1
        : 4,
    slidesToScroll: 1,
    autoplay: officeUserData?.data?.data.length > 1,
    autoplaySpeed: 3500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (!Array.isArray(officeUserData?.data?.data)) return <div></div>;

  return (
    <div id="trustee">
      <MDBContainer>
        <MDBRow>
          <MDBCol>
            <div className="admin">
              <div className="heading mb-0">
                <h3 className="mb-0 text-black">OFFICE BEARERS</h3>
              </div>
            </div>

            <Slider ref={sliderRef} {...settings} className="py-3">
              {officeUserData.data.data.map((user) => (
                <div key={user._id} className="d-flex justify-content-center align-items-center">
                  <MDBCard
                    className="custom-card"
                    style={{ borderRadius: "15px", height: "380px" }}
                  >
                    <MDBCardBody className="text-center ">
                      <div className="mt-3 mb-4 d-flex justify-content-center">
                        <MDBCardImage
                          src={user.profilePicUrls ? user.profilePicUrls : "https://i0.wp.com/digitalhealthskills.com/wp-content/uploads/2022/11/3da39-no-user-image-icon-27.png?fit=500%2C500&ssl=1"}
                          alt={user.name}
                          // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHgxF6IYQSJBeP1bsjTs0Yi17yzN4_CGOm-wXoXtLslQ&s"
                          className="rounded-circle"
                          fluid
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <MDBTypography tag="h4">{user.name}</MDBTypography>
                      <MDBCardText className="text-muted mb-2">
                        {user.designation} <span className="mx-2">|</span>{" "}
                        <br />
                        <a
                          href={`mailto:${user.email}`}
                          style={{ color: "#757575" }}
                        >
                          {user.email}
                        </a>
                      </MDBCardText>
                      <MDBCardText className="text-muted mb-3">
                        {user.description}
                      </MDBCardText>
                      <MDBBtn
                        size="lg"
                        style={{ backgroundColor: "#2A2EEC", borderRadius:"10px" }}
                      >
                        <a className="text-white" href={`tel:${user.number}`}>
                          {" "}
                          Call
                        </a>
                      </MDBBtn>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              ))}
            </Slider>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default Trustee;
