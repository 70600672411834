import React, { useEffect } from "react";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import "../css/Custom.css";
import "../Style/Font.css";

function ContactForm() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <section className="kd-contact-wrapper">
      <div className="contact-container">
        <div>
          <h3 className="text-uppercase mb-0">Contact Us</h3>
        </div>
        <div className="kd-breadcrumb">
          <a href="../../index.html">Home</a> / Contact Form{" "}
        </div>
      </div>
      <div className="container">
        <div className="kd-contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19356.43626719406!2d70.9701378!3d21.8854123!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39584327ccf7b42f%3A0x1f1db81bc11e3e20!2sSHREE%20LEUVA%20PATEL%20SAMAJ%20-%20DODIYALA!5e1!3m2!1sen!2sin!4v1723892004046!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{border:0}}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3704.74129220815!2d70.68886826956914!3d21.790271472583235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39589ff11ef68687%3A0xa31dea34ebd2624b!2sShree%20Khodaldham%20Temple!5e0!3m2!1sen!2sin!4v1578742246937!5m2!1sen!2sin"
            // style={{ border: 0 , width: "auto" , height: "auto"}}
            allowFullScreen=""
          /> */}
        </div>
        <div className="row">
          <div className="col-lg-9">
            <div className="kd-contact-now-block">
              <h3>CONTACT NOW</h3>
              <p className="kd-sub-title">
                And we'll get back to you within 12 hours.
              </p>
              <div className="kd-contact-form">
                <form>
                  <div
                    className="alert alert-danger form_error tag_none"
                    style={{ display: "none" }}
                  ></div>
                  <input
                    type="hidden"
                    name="frm_page"
                    defaultValue="contact-us/"
                  />
                  <div className="form-row">
                    <div className="col-md-3">
                      <label htmlFor="cf_cname">
                        Name 
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="cf_cname"
                        name="cname"
                        required=""
                      />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="cf_eaddress">
                        Email 
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="eaddress"
                        id="cf_eaddress"
                        required=""
                      />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="cf_cnumber">
                        Mobile Number 
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="cnumber"
                        id="cf_cnumber"
                        required=""
                      />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="cf_clocation">
                        City 
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="clocation"
                        id="cf_clocation"
                        required=""
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="cf_message">
                        INFORMATION 
                      </label>
                      <textarea
                        className="form-control"
                        name="message"
                        id="cf_message"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <button
                    className="kd-submit-btn kd-submit-book mt-3"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="kd-contact-info">
              <p>
                <strong>Shree Leuva Patel Samaj Trust - Dodiyala</strong>
                <br />
                Sanathli road, Dodiyala Ta.Jasdan Dist.Rajkot
                <br />
                Dodiyala, Rajkot - 365490,
                <br />
                Mo.No.: +91 9510731828
              </p>
              {/* <p>
                <br />
                <strong>Shri Khodaldham Temple-Kagvad:</strong>
                <br />
                Mu-Kagwad, Ta-Jetpur,
                <br />
                Dist: Rajkot,
                <br />
                Email:{" "}
                <a href="mailto:info@khodaldhamtrust.org">
                  info@khodaldhamtrust.org
                </a>
                <br />
                Phone: 02823-252000,
                <br />
                Mo. No.: +91 8780460015
              </p> */}
              <p>&nbsp;</p>
              <div className="social-links">
                <a
                  href="https://www.facebook.com/share/E1nwb9mopJN3NDSi/?mibextid=qi2Omg"
                  target="_blank"
                  rel="noopener"
                  className="social-icon si-borderless nobottommargin"
                  title="facebook link"
                  style={{
                    color: "black",
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "-60px",
                  }}
                >
                  <FaFacebook />
                </a>
                <a
                  href="https://www.instagram.com/shreeleuvapatelsamajdodiyala"
                  target="_blank"
                  rel="noopener"
                  className="social-icon si-borderless nobottommargin"
                  title="instagram link"
                  style={{
                    color: "black",
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "-60px",
                  }}
                >
                  <FaInstagram />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
