import React from "react";
import "../css/About.css";

const About = () => {
  return (
    <>
      <div id="about">
        <div className="d-flex justify-content-center flex-wrap ">
          <div
            className="abt-card card m-3  text-center "
            style={{ width: "250px" }}
          >
            <a href="#">CAMPUS ACTIVITY</a>
          </div>
          <div
            className="abt-card card m-3 p-3 text-center"
            style={{ width: "250px" }}
          >
            <a href="#">CAMPUS FACILITY</a>
          </div>
          <div
            className="abt-card card m-3 p-3 text-center"
            style={{ width: "250px" }}
          >
            <a href="#">DONATION</a>
          </div>
        </div>
        <div className="section kd-info-section">
          <div className="container clearfix">
            <div className="row justify-content-center">
              <div className="col-md-8 center">
                <div className="heading-block nobottomborder mb-4">
                  <h2 className="mb-4 ">
                    <span className="text-black">Who we are</span>
                  </h2>
                </div>
                <p>
                  Shree Leuva Patel Samaj Dodiyala is a community organization
                  dedicated to the cultural and social development of the Leuva
                  Patel community in Dodiyala, Gujarat. Our organization was
                  founded in 2023 with the goal of promoting unity and
                  collaboration within our community.
                  
                  We believe that by working together, we can achieve great
                  things and make a positive impact on our community. Our
                  organization is committed to providing a range of services and
                  programs to support the growth and development of our
                  community.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
