import React, { useEffect } from "react";
import "../Style/Gallery.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGalleries } from "../Store/slice/samajSlice";

const Gallery = () => {

  const dispatch = useDispatch();
  
  const { galleryData, loading} = useSelector(
    (state) => state.samaj || { galleryData: [], loading: false}
  )

  // console.log('galleryData :>> ', galleryData);

  useEffect(() => {
    if (!loading && galleryData.length === 0) {
      dispatch(getGalleries());
    }
  }, [dispatch, loading, galleryData.length])

  if (!Array.isArray(galleryData?.data?.data)) return <div>Data not found</div>;

  return (
    <div id="gallery">
      <h1 className="text-black mt-4">GALLERY</h1>
      <div className="gallery">
        {galleryData?.data?.data.map((imgurl, index) => (
          <Link
            key={imgurl._id}
            to={`/gallery/${imgurl._id}`}
            // style={{ margin: "10px" }}
          >
            <div key={index} className="gallery-item">
              <div className="img-cntnr" >
                <img src={imgurl.thumbnailImageUrl ? imgurl.thumbnailImageUrl : " "} className="gallery-image" alt={imgurl.title}/>
              </div>
              <div className="image-title">{imgurl.title}</div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Gallery;