import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "../Style/Gallery.css";
import { useDispatch, useSelector } from "react-redux";
import { getGalleries } from "../Store/slice/samajSlice";

function GalleryImages() {
  const { imgurlId } = useParams();
  const dispatch = useDispatch();

  console.log('imgurlId :>> ', imgurlId);

  const galleryData = useSelector(state => state.samaj.galleryData);
  const Gallery = galleryData?.data?.data.find(p => p._id === imgurlId);

  console.log("Gallery :>> ", Gallery.imageUrls);

  useEffect(() => {
    if (galleryData.length === 0) {
      dispatch(getGalleries());
    }
  }, [dispatch, galleryData.length]);

  if (!Gallery) return <p>Gallery Images not found</p>;

  return (
    <div>
      <div className="galler-details" style={{margin:"50px 0px"}}>
        <h1 className="text-black" style={{textDecoration:"underline"}}>{Gallery.title}</h1>
        {Gallery?.imageUrls.map((img, index) => (
          <img key={index} src={img} alt={`img ${index + 1}`} style={{ width: "150px", margin:"0px 5px" }} />
        ))}
      </div>
    </div>
  );
}

export default GalleryImages;
