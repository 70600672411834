import React, { useEffect, useState } from "react";
import { Card, Modal, Carousel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "../Style/Event.css";
import { getUpcomingEvents } from "../Store/slice/samajSlice";
import first from "../GalleryImages/1.jpg";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ImageList,
  ImageListItem,
  makeStyles,
  Typography,
  Button
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4A90E2",
    },
    secondary: {
      main: "#7B92B2",
    },
    background: {
      default: "#F4F7FA",
    },
    text: {
      primary: "#333",
      secondary: "#555",
    },
  },
  typography: {
    fontFamily: `'Poppins', sans-serif`,
    h6: {
      fontWeight: 600,
    },
    body1: {
      lineHeight: 1.75,
    },
  },
  shadows: [
    "none",
    "0px 1px 3px rgba(0,0,0,0.2), 0px 1px 1px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    "0px 3px 6px rgba(0,0,0,0.2), 0px 3px 4px rgba(0,0,0,0.14), 0px 1px 8px rgba(0,0,0,0.12)",
    "0px 10px 20px rgba(0,0,0,0.15)", // Custom shadow for cards
  ],
});

// Styled components
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#D8E6F3",
  color: "#000000",
  padding: theme.spacing(2),
  textAlign: "center",
  fontWeight: "bold",
  borderRadius: "8px 8px 0 0",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: 600,
  color: theme.palette.secondary.main,
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: "-4px",
    width: "40px",
    height: "4px",
    backgroundColor: theme.palette.primary.main,
  },
}));

const StyledImageListItem = styled(ImageListItem)(({ theme }) => ({
  borderRadius: 8,

  overflow: "hidden",
  maxWidth: "150px", // Max width for each image
  maxHeight: "100px",
  // transition: "transform 0.3s ease",
  // "&:hover": {
  //   transform: "scale(1.05)",
  // },
  "& img": {
    // boxShadow: theme.shadows[3],
    // width: "100%",
    // height: "100%",

    objectFit: "cover",
  },
}));

function Event() {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const { upcomingEventsData, loading } = useSelector(
    (state) => state.samaj || { upcomingEventsData: [], loading: false }
  );

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!loading && upcomingEventsData.length === 0) {
      dispatch(getUpcomingEvents());
    }
  }, [dispatch, loading, upcomingEventsData.length]);

  const handleCardClick = (event) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  const settings = {
    dots: true,
    infinite: upcomingEventsData?.data?.data.length > 1,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: upcomingEventsData?.data?.data.length > 1,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".fade-up");
      elements.forEach((element) => {
        const elementTop = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (elementTop < windowHeight * 0.75) {
          element.classList.add("active");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
    
  useEffect(() => {
    if (showModal) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Clean up function
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [showModal]);

  if (!Array.isArray(upcomingEventsData?.data?.data)) return null;

  return (
    <div id="event">
      <h2 className="event-title text-black">UPCOMING EVENTS</h2>
      <div className="px-5 py-3">
        <Slider {...settings}>
          {upcomingEventsData.data.data.map((data, index) => (
            <div key={index} className="cards-cntnr px-3 fade-up">
              <div className="animate-up" onClick={() => handleCardClick(data)}>
                <Card className="cards">
                  {/* {data.images.map((img, imgIndex) => ( */}
                  <Card.Img
                    // key={imgIndex}
                    variant="top"
                    src={data.images[0]}
                    alt="cc"
                    style={{ height: "400px", objectFit: "cover" }}
                  />
                  {/* // <Card.Img variant="top" src={`${upcomingEventsData.imageBaseUrl}${imgs.url}`} alt={imgs.name} /> */}
                  {/* ))} */}
                  <Card.Body>
                    <Card.Title>{data.title}</Card.Title>
                    <Card.Text>{data.description}</Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* <Dialog
        open={showModal}
        // TransitionComponent={Transition}
        keepMounted
        fullWidth
        // onClose={() => setShowModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            style={{ fontFamily: '"Montserrat", sans-serif' }}
          >
            {selectedEvent?.title}
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            onClick={() => setShowModal(false)}
          >
            <CloseIcon />
          </Typography>
        </Box>
        <DialogContent>
          <Slider {...settings}>
            <Carousel>
              {selectedEvent?.images.map((img, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100 h-50"
                    style={{ objectFit: "contain" }}
                    src={img}
                    alt={img.name}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Slider>
          <DialogContentText id="alert-dialog-slide-description">
            {selectedEvent?.title}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            {selectedEvent?.description}
          </DialogContentText>
        </DialogContent>
      </Dialog> */}

      <ThemeProvider theme={theme}>
        <Dialog
          open={showModal}
          onClose={() => setShowModal(false)}
          maxWidth="md"
          fullWidth
          disableScrollLock={false}
          PaperProps={{
            style: {
              borderRadius: 12,
              overflow: "hidden",
            },
          }}
        >
          <StyledDialogTitle>{selectedEvent?.title}</StyledDialogTitle>
          <StyledDialogContent dividers>
            <Grid container spacing={2}>
              {/* Event Date and Time */}
              <Grid item xs={6} md={6} lg={6}>
                <StyledTypography variant="h6">Event Date</StyledTypography>
                <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
                  {new Date(selectedEvent?.dateTime.date).toLocaleDateString()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <StyledTypography variant="h6">Event Time</StyledTypography>
                <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
                  {selectedEvent?.dateTime.time}
                </Typography>
              </Grid>

              {/* Contact Person Details */}
              <Grid item xs={6} md={6} lg={6}>
                <StyledTypography variant="h6">Contact Person</StyledTypography>
                <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
                  Name: {selectedEvent?.contactPerson.name}
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
                  Number: {selectedEvent?.contactPerson.number}
                </Typography>
              </Grid>

              {/* Event Description */}
              <Grid item xs={12}>
                <StyledTypography variant="h6">Description</StyledTypography>
                <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
                  {selectedEvent?.description}
                </Typography>
              </Grid>

              {/* Event Images */}
              <Grid item xs={12}>
                <StyledTypography variant="h6">Images</StyledTypography>
                <ImageList cols={6} gap={8}>
                  {selectedEvent?.images.map((image, index) => (
                    <StyledImageListItem key={index}>
                      <img
                        src={image}
                        alt={`Event-pic ${index + 1}`}
                        loading="lazy"
                        style={{
                          width: "100%",
                          height: "100%",
                          maxWidth: "200px", // Max width for each image
                          maxHeight: "150px", // Max height for each image
                          borderRadius: "8px",
                        }}
                      />
                    </StyledImageListItem>
                  ))}
                </ImageList>
              </Grid>
            </Grid>
          </StyledDialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowModal(false)}
              sx={{
                // margin: 2,
                backgroundColor: "#2A2EEC",
                color: "#fff",
                fontWeight: 600,
                padding: "12px 32px",
                borderRadius: "30px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                "&:hover": {
                  backgroundColor: "#2A2EEC",
                  boxShadow: "0 6px 12px rgba(0,0,0,0.3)",
                },
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>

      {/* Modal for displaying the selected event details */}
      {/* <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header>
          <Modal.Title style={{ margin: "0 auto" }}>
            {selectedEvent?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="event-details-container">
            <Carousel>
              {selectedEvent?.images.map((img, index) => (
                <Carousel.Item key={index}>
                  <img className="d-block w-100" src={img} alt={img.name} />
                </Carousel.Item>
              ))}
            </Carousel>
            <p>{selectedEvent?.title}</p>
            <p>{selectedEvent?.description}</p>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
}

export default Event;
